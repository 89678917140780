export function fetchWithAuth(url, options = {}) {
  const token = localStorage.getItem('token');
  
  // Ensure headers exist and add the Authorization header
  const headers = {
    ...options.headers,
    'Authorization': `Bearer ${token}`,
  };
  
  return fetch(url, {
    ...options,
    headers,
  });
}
