import React, { useState, useEffect } from 'react';
import LoginPage from './LoginPage'; // Import the LoginPage component
import App from './App'; // Import your main application

const AppWithAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Handle successful login
  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  // Handle logout (optional, if you want to add logout functionality)
  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  // Conditionally render the login page or the main app
  if (!isAuthenticated) {
    return <LoginPage onLoginSuccess={handleLoginSuccess} />;
  }

  // Render the main app if authenticated
  return <App />;
};

export default AppWithAuth;
