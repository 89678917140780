import React, { useState, useEffect } from 'react';
import TableView from './TableView';
import GenerateForm from './GenerateForm';
import TerminalView from './TerminalView';
import ConfigView from './ConfigView';

const App = () => {
  const [activeTab, setActiveTab] = useState('table');
  const [tabs, setTabs] = useState([]);
  const [iframes, setIframes] = useState({});

  useEffect(() => {
    setTabs([
      { id: 'table', label: 'Active Hosts', component: <TableView onUrlSelect={handleUrlSelect} />, scrollable: true },
      { id: 'generate', label: 'Generate', component: <GenerateForm />, scrollable: false },
      { id: 'configure', label: 'Configure', component: <ConfigView />, scrollable: false },
    ]);
  }, []);

  const handleUrlSelect = (url, name, type) => {
    const newTabId = `iframe-${url}`;

    setTabs((prevTabs) => {
      if (!prevTabs.some((tab) => tab.id === newTabId)) {
        const newTab = {
          id: newTabId,
          label: `${name}-${type}`,
          isClosable: true,
          scrollable: true,
        };
        const newTabs = [...prevTabs, newTab];
        return newTabs;
      }
      return prevTabs;
    });

    setIframes((prevIframes) => {
      if (!prevIframes[newTabId]) {
        return {
          ...prevIframes,
          [newTabId]: url,
        };
      }
      return prevIframes;
    });

    setActiveTab(newTabId);
  };

  const closeTab = (tabId) => {
    setTabs((prevTabs) => prevTabs.filter((tab) => tab.id !== tabId));

    setIframes((prevIframes) => {
      const updatedIframes = { ...prevIframes };
      delete updatedIframes[tabId];
      return updatedIframes;
    });

    if (activeTab === tabId) {
      setActiveTab(tabs[0]?.id || 'table');
    }
  };

  const renderActiveTabContent = () => {
    return (
      <>
        {tabs.map((tab) => {
          if (tab.id.startsWith('iframe-')) {
            return (
              <iframe
                key={tab.id}
                src={iframes[tab.id]}
                title={`Iframe for ${tab.id}`}
                className={`w-full h-full border border-crimson ${activeTab === tab.id ? 'block' : 'hidden'}`}
              />
            );
          }

          if (activeTab === tab.id) {
            return (
              <div key={tab.id} className="w-full h-full">
                {tab.component}
              </div>
            );
          }

          return null;
        })}
      </>
    );
  };

  return (
    <div className="h-screen w-screen flex flex-col bg-midnight text-white font-mono overflow-x-hidden overflow-y-auto">
      {/* Top Section */}
      <div className="flex justify-between p-4 border-b border-stone-700 bg-stone-950 shadow-lg">
        <div className="text-3xl font-bold text-red-800">VCL Hub</div>
        <div className="text-sm text-stone-400">"Hack yourself..."</div>
      </div>

      {/* Tabs */}
      <div className="flex justify-between items-center border-b border-stone-700 bg-stone-900 shadow-inner h-14">
        {/* Scrollable Tabs on the Left */}
        <div className="flex overflow-x-auto no-scrollbar h-full w-full">
          {tabs
            .filter((tab) => tab.scrollable)
            .map((tab) => (
              <div key={tab.id} className="relative flex h-full">
                <button
                  className={`px-6 py-3 text-left font-bold transition-all whitespace-nowrap h-full ${
                    activeTab === tab.id
                      ? 'bg-stone-800 text-red-700 border-b-4 border-red-700'
                      : 'text-stone-400 hover:bg-stone-800 hover:text-white'
                  }`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </button>
                {tab.isClosable && (
                  <button
                    className="absolute right-0 top-0 px-2 py-1 text-red-500 hover:text-red-700"
                    onClick={() => closeTab(tab.id)}
                  >
                    &#x2715;
                  </button>
                )}
              </div>
            ))}
        </div>

        {/* Non-scrollable Tabs on the Right */}
        <div className="flex h-full">
          {tabs
            .filter((tab) => !tab.scrollable)
            .map((tab) => (
              <div key={tab.id} className="relative flex h-full">
                <button
                  className={`px-6 py-3 text-left font-bold transition-all whitespace-nowrap h-full ${
                    activeTab === tab.id
                      ? 'bg-stone-800 text-red-700 border-b-4 border-red-700'
                      : 'text-stone-400 hover:bg-stone-800 hover:text-white'
                  }`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </button>
              </div>
            ))}
        </div>
      </div>

      {/* Active Tab Content */}
      <div className="flex-1 m-8 overflow-y-auto overflow-x-hidden bg-stone-900">
        {renderActiveTabContent()}
      </div>

      {/* Terminal Section - Render only if it's not an iframe tab */}
      {!activeTab.startsWith('iframe-') && (
        <div
          className="relative border border-crimson mb-8 ml-8 mr-8 shadow-lg"
          style={{ maxHeight: '25%', overflow: 'hidden', position: 'relative' }}
        >
          <div
            className="left-4 bg-stone-900 px-2 py-2 text-sm text-stone-300"
            style={{
              borderBottom: 'none',
            }}
          >
            Event Log
          </div>
          <div className="h-full">
            <TerminalView />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;

