import React, { useState } from 'react';

const LoginPage = ({ onLoginSuccess }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  // Handle password input change
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    })
    .then(response => response.json())
    .then(data => {
      localStorage.setItem('token', data.access_token);
      onLoginSuccess(); // Call the parent function on successful login
    })
    .catch(err => setError(`Error logging in ${err}. Please try again later.`));
  };

  return (
    <div className="h-screen flex justify-center items-center bg-midnight">
      <div className="w-full max-w-md p-8 bg-stone-950 shadow-lg border border-dashed border-crimson">
        {error && <div className="text-red-500 mb-4 text-center">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="password"
              id="password"
              name="password"
              className="w-full p-2 bg-stone-800 border border-stone-600 text-white focus:outline-none focus:border-red-600 hover:border-red-600 transition duration-300 ease-in-out"
              value={password}
              onChange={handleInputChange}
              placeholder="Access Token"
              style={{ borderRadius: '0px', borderWidth: '1px' }} // Remove default border-radius
              required
            />
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className="w-full py-2 bg-red-900 hover:bg-red-950 text-white font-bold shadow-lg hover:shadow-xl transition-transform transform duration-300 ease-in-out"
            >
              VCL Hub Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;

