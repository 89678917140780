import React, { useEffect, useState } from 'react';
import { fetchWithAuth } from './Helpers';

const ConfigView = () => {
  const [config, setConfig] = useState({
    PING_INTERVAL: '',
    ACCESS_PASSWORD: '',
    LOG_LEVEL: 'INFO', // Default to INFO log level
  });
  const [error, setError] = useState(null);

  // Fetch the current configuration from /config/get
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetchWithAuth('/config/get');
        const data = await response.json();
        setConfig((prevConfig) => ({
          ...prevConfig,
          ...data, // Merge fetched data with existing config
        }));
      } catch (err) {
        setError('Failed to load configuration.');
      }
    };
    fetchConfig();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
  };

  // Handle form submission to update config
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetchWithAuth('/config/set', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(config),
      });
      if (response.ok) {
        alert('Configuration updated successfully');
      } else {
        alert('Failed to update configuration');
      }
    } catch (err) {
      alert('Error updating configuration');
    }
  };

  return (
    <div className="h-full flex flex-col justify-between border border-crimson p-4 overflow-auto bg-stone-950 bg-opacity-80">
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="flex flex-col justify-between h-full">
        <div className="grid grid-cols-2 gap-4">
          {/* PING_INTERVAL */}
          <div>
            <label className="block">Default Ping Interval (seconds)</label>
            <input
              type="number"
              name="PING_INTERVAL"
              className="w-full p-2 bg-stone-800 border border-stone-600 focus:outline-none focus:border-red-600 "
              value={config.PING_INTERVAL || ''}
              onChange={handleInputChange}
              placeholder="30"
            />
          </div>

          {/* ACCESS_PASSWORD */}
          <div>
            <label className="block">Access Password</label>
            <input
              type="string"
              name="ACCESS_PASSWORD"
              className="w-full p-2 bg-stone-800 border border-stone-600 focus:outline-none focus:border-red-600 "
              value={''}
              onChange={handleInputChange}
              placeholder="********"
            />
          </div>

          {/* LOG_LEVEL */}
          <div>
            <label className="block">Log Level</label>
            <select
              name="LOG_LEVEL"
              className="w-full p-2 bg-stone-800 border border-stone-600 focus:outline-none"
              value={config.LOG_LEVEL}
              onChange={handleInputChange}
            >
              <option value="DEBUG">DEBUG</option>
              <option value="INFO">INFO</option>
              <option value="WARNING">WARNING</option>
              <option value="ERROR">ERROR</option>
              <option value="CRITICAL">CRITICAL</option>
            </select>
          </div>
        </div>

        {/* Submit Button at the Bottom */}
        <div className="mt-4 self-start">
          <button
            type="submit"
            className="px-4 py-2 bg-red-800 hover:bg-red-900 text-white font-bold w-full"
          >
            Save Configuration
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConfigView;

