import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from './Helpers';  


const GenerateForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false); // Track the form submission
  const [loadingText, setLoadingText] = useState(''); // For animated dots
  const [useDhcp, setUseDhcp] = useState(true);
  const [networkSettings, setNetworkSettings] = useState({
    WifiSSID: '',
    WifiPassword: '',
    WifiCountryCode: 'US',
    Hostname: '',
    deviceType: 'Raspberry Pi 4',
    IPAddress: '',
    netmask: '',
    DefaultGateway: '',
    DNSServer: '',
    UseDHCP: true,
  });
  const [isFormValid, setIsFormValid] = useState(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNetworkSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle DHCP toggle
  const handleDhcpToggle = () => {
    setUseDhcp(!useDhcp);
    setNetworkSettings((prev) => ({
      ...prev,
      UseDHCP: !useDhcp,
      IPAddress: '',
      netmask: '',
      DefaultGateway: '',
      DNSServer: '',
    }));
  };

  // Validate the form
  useEffect(() => {
    const { WifiSSID, WifiPassword, Hostname, UseDHCP, IPAddress, netmask, DefaultGateway, DNSServer } = networkSettings;
    const isValid = WifiSSID && WifiPassword && Hostname && (UseDHCP || (IPAddress && netmask && DefaultGateway && DNSServer));
    setIsFormValid(isValid);
  }, [networkSettings]);

  // Loading animation for dots
  useEffect(() => {
    let interval;
    if (isSubmitting) {
      interval = setInterval(() => {
        setLoadingText((prev) => (prev === '...' ? '' : prev + '.'));
      }, 500); // Animate every 500ms
    } else {
      setLoadingText(''); // Reset text when not submitting
    }

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [isSubmitting]);

  const handleDownloadClick = async () => {
    setIsSubmitting(true); // Start submission process

    // Simulate network request
    const response = await fetchWithAuth('/provision/pi', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(networkSettings),
    });

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'pi.iso'; // Specify the filename
    a.click();

    setIsSubmitting(false); // End submission process
  };

  return (
    <div className="h-full flex flex-col justify-between border border-crimson p-4 overflow-auto bg-stone-950 bg-opacity-80">
      <div>
        <div className="grid grid-cols-2 gap-4">
          {/* WiFi SSID */}
          <div>
            <label className="block">WiFi SSID</label>
            <input
              type="text"
              name="WifiSSID"
              className="w-full p-2 bg-stone-800 border border-stone-600 text-sm focus:outline-none focus:border-red-600 "
              value={networkSettings.WifiSSID}
              onChange={handleInputChange}
            />
          </div>

          {/* WiFi Password */}
          <div>
            <label className="block">WiFi Password</label>
            <input
              type="password"
              name="WifiPassword"
              className="w-full p-2 bg-stone-800 border border-stone-600 text-sm focus:outline-none focus:border-red-600 "
              value={networkSettings.WifiPassword}
              onChange={handleInputChange}
            />
          </div>

          {/* WiFi Country Code */}
          <div>
            <label className="block">WiFi Country Code</label>
            <select
              name="WifiCountryCode"
              className="w-full p-2 bg-stone-800 border border-stone-600 text-sm box-border focus:outline-none "
              value={networkSettings.WifiCountryCode}
              onChange={handleInputChange}
            >
              <option value="US">United States</option>
              <option value="GB">United Kingdom</option>
              <option value="DE">Germany</option>
              <option value="FR">France</option>
              <option value="JP">Japan</option>
            </select>
          </div>

          {/* Hostname */}
          <div>
            <label className="block">Hostname</label>
            <input
              type="text"
              name="Hostname"
              className="w-full p-2 bg-stone-800 border border-stone-600 text-sm focus:outline-none focus:border-red-600 "
              value={networkSettings.Hostname}
              onChange={handleInputChange}
            />
          </div>

          {/* Device Type Selection */}
          <div>
            <label className="block">Device Type</label>
            <select
              name="deviceType"
              className="w-full p-2 bg-stone-800 border border-stone-600 text-sm focus:outline-none "
              value={networkSettings.deviceType}
              onChange={handleInputChange}
            >
              <option value="Raspberry Pi 4">Raspberry Pi 4</option>
              <option value="Intel NUC">Intel NUC</option>
            </select>
          </div>
        </div>

        {/* DHCP Toggle */}
        <div className="mt-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={networkSettings.UseDHCP}
              onChange={handleDhcpToggle}
              className="mr-2"
            />
            Use DHCP
          </label>
        </div>

        {/* Network Settings (only if DHCP is disabled) */}
        {!networkSettings.UseDHCP && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <label className="block">IP Address</label>
              <input
                type="text"
                name="IPAddress"
                className="w-full p-2 bg-stone-800 border border-stone-600 text-sm focus:outline-none focus:border-red-600 "
                value={networkSettings.IPAddress}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label className="block">Netmask</label>
              <input
                type="text"
                name="netmask"
                className="w-full p-2 bg-stone-800 border border-stone-600 text-sm focus:outline-none focus:border-red-600 "
                value={networkSettings.netmask}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label className="block">Default Gateway</label>
              <input
                type="text"
                name="DefaultGateway"
                className="w-full p-2 bg-stone-800 border border-stone-600 text-sm focus:outline-none focus:border-red-600 "
                value={networkSettings.DefaultGateway}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label className="block">DNS Server</label>
              <input
                type="text"
                name="DNSServer"
                className="w-full p-2 bg-stone-800 border border-stone-600 text-sm focus:outline-none focus:border-red-600 "
                value={networkSettings.DNSServer}
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}
      </div>

      {/* Download ISO Button */}
      <div className="mt-4 self-start">
        <button
          className={`px-4 py-2 ${isFormValid ? 'bg-red-800 hover:bg-red-900' : 'bg-gray-600'} text-white font-bold`}
          disabled={!isFormValid || isSubmitting}
          onClick={handleDownloadClick}
        >
          {isSubmitting ? `Generating${loadingText}` : 'Download ISO'}
        </button>
      </div>
    </div>
  );
};

export default GenerateForm;
