import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal, faDesktop } from '@fortawesome/free-solid-svg-icons';
import { fetchWithAuth } from './Helpers';;

const TableView = ({ onUrlSelect }) => {
  const [rowData, setRowData] = useState([]);
  const gridApiRef = useRef(null); // Reference for AG Grid API

  const fetchPeerData = async () => {
    try {
      const response = await fetchWithAuth('/status/peers');
      const data = await response.json();
      setRowData(data);
    } catch (error) {
      console.error('Error fetching peer data:', error);
    }
  };

  useEffect(() => {
    const handleRefreshVCITable = () => {
      fetchPeerData();
    };

    window.addEventListener('refreshTable', handleRefreshVCITable);

    return () => {
      window.removeEventListener('refreshTable', handleRefreshVCITable);
    };
  }, []);

  const isLastActivityRecent = (lastActivity) => {
    const lastActivityTime = new Date(lastActivity * 1000);
    const currentTime = new Date();
    const diffInSeconds = (currentTime - lastActivityTime) / 1000;
    return diffInSeconds <= 60; // Return true if within 60 seconds
  };

  const timeAgo = (timestamp) => {
    const now = new Date().getTime();
    const differenceInSeconds = Math.floor((now - timestamp * 1000) / 1000);

    if (differenceInSeconds < 60) {
      return `Now`;
    } else if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60);
      return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else if (differenceInSeconds < 86400) {
      const hours = Math.floor(differenceInSeconds / 3600);
      return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    } else {
      const days = Math.floor(differenceInSeconds / 86400);
      return days === 1 ? `${days} day ago` : `${days} days ago`;
    }
  };

  // Column definitions using proportional `flex` for width
  const columnDefs = [
    { headerName: 'ID', field: 'id', filter:false, flex: 0.3 },
    { headerName: 'Hostname', field: 'hostname', filter: true, flex: 1 },
    { headerName: 'VPN IP', field: 'vpnIp', filter: true, flex: 1 },
    { headerName: 'Local IP', field: 'localIp', filter: true, flex: 1 },
    {
      headerName: 'Last Activity',
      field: 'lastActivity',
      filter: true,
      flex: 0.75,
      cellRenderer: (params) => timeAgo(params.value),
    },
    { headerName: 'Arch', field: 'arch', filter: true, flex: 0.4 },
    { headerName: 'Version', field: 'version', filter: true, flex: 0.5 },
    {
      headerName: 'Description',
      field: 'description',
      filter: true,
      flex: 1,
      editable: true, // Enable editing for the description field
    },
    {
      headerName: 'Actions',
      filter: false,
      field: 'actions',
      flex: 0.5,
      cellRenderer: (params) => {
        const isRecent = isLastActivityRecent(params.data.lastActivity);
        return (
          <div className="flex justify-end space-x-4">
            <button
              className={`text-emerald-600 ${!isRecent ? 'opacity-50 cursor-not-allowed' : 'hover:text-green-700'}`}
              onClick={() => isRecent && onUrlSelect(`/proxy/${params.data.vpnIp}:5005`, params.data.hostname, 'term')}
              title="Open Terminal"
              disabled={!isRecent}
            >
              <FontAwesomeIcon icon={faTerminal} />
            </button>
            <button
              className={`text-sky-600 ${!isRecent ? 'opacity-50 cursor-not-allowed' : 'hover:text-blue-700'}`}
              onClick={() => isRecent && onUrlSelect(`/proxy/${params.data.vpnIp}:5000`, params.data.hostname, 'gui')}
              title="Open GUI"
              disabled={!isRecent}
            >
              <FontAwesomeIcon icon={faDesktop} />
            </button>
          </div>
        );
      },
    },
  ];

  const onCellValueChanged = async (params) => {
    if (params.colDef.field === 'description') {
      try {
        const response = await fetchWithAuth(`/peer/update/${params.data.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ description: params.newValue }),
        });
        if (!response.ok) {
          console.error('Failed to update description');
        } else {
          console.log('Description updated successfully');
        }
      } catch (error) {
        console.error('Error updating description:', error);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchPeerData();
    }, 60000); // Refresh every 60 seconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchPeerData();
  }, []);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  return (
    <div className="h-full overflow-auto">
      <div
        className="ag-theme-alpine-dark h-full w-full"
        style={{
          '--ag-background-color': '#0D0D0E',
          '--ag-header-background-color': '#181819',
          '--ag-header-foreground-color': '#EEEEEF',
          '--ag-border-color': '#732422',
          '--ag-odd-row-background-color': '#202020',
          '--ag-even-row-background-color': '#1F1F1F',
          '--ag-row-hover-color': '#510F0F', // Darker red with opacity
          '--ag-selected-row-background-color': '#333333',
          '--ag-font-size': '14px',
          '--ag-foreground-color': '#EEEEEF',
          '--ag-checkbox-foreground-color': '#EEEEEF',
          '--ag-border-radius': '0px',
          '--ag-row-height': '30px',
          '--ag-header-height': '40px',
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{
            resizable: true,
            sortable: true,
            filter: true,
            headerClass: 'font-mono text-stone-300',
            cellClass: 'font-mono text-stone-400',
          }}
          onCellValueChanged={onCellValueChanged}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
};

export default TableView;

