import React, { useEffect, useRef } from 'react';
import { Terminal } from 'xterm';
import 'xterm/css/xterm.css';
import { FitAddon } from 'xterm-addon-fit';
import io from 'socket.io-client';

const TerminalView = () => {
  const terminalRef = useRef(null);
  const fitAddon = useRef(new FitAddon());

  useEffect(() => {
    console.log("init terminal");
    const terminal = new Terminal({
      cursorBlink: false, // Disable cursor blink
      cursorStyle: 'underline', // Set cursor style to none
			cursorInactiveStyle: "none",
      theme: {
        background: '#0D0D0F',
        foreground: '#EEEEF0',
        cursor: 'transparent', // Hide the cursor color
      },
      fontFamily: 'monospace',
    });

    terminal.loadAddon(fitAddon.current);
    terminal.open(terminalRef.current);
    fitAddon.current.fit();

    const websocketUrl = `wss://${window.location.host}/logging`;
    const token = localStorage.getItem('token'); // or retrieve from wherever it's stored
    const socket = new io.connect(websocketUrl, {
      extraHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    socket.onopen = () => {
      console.log("open ws");
      terminal.writeln('Connected to WebSocket...');
    };

    socket.on('action', (event) => {
      if (event.action === 'refreshTable') {
        const tableRefreshEvent = new CustomEvent('refreshTable');
        window.dispatchEvent(tableRefreshEvent); // Dispatch the custom event to refresh the table
      }
    });

    socket.addEventListener('message', (event) => {
      terminal.write(event.message);
    });

    socket.onclose = () => {
      terminal.writeln('WebSocket closed');
      terminal.dispose();
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      terminal.writeln('WebSocket error');
    };
  }, []);

  return (
    <div
      ref={terminalRef}
      style={{
        height: '100%',
        overflow: 'hidden', // Hide scrollbar
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
      }}
    ></div>
  );
};

export default TerminalView;

